// @flow

import React from "react";
import {
    CreateRequestLayout,
    RequestOverview as _RequestOverview,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const RequestOverview = (props: *) => (
    <Layout {...props}>
        <CreateRequestLayout>
            <_RequestOverview />
        </CreateRequestLayout>
    </Layout>
);
export default withPrivateRoute(RequestOverview);
